import styles from './external-connected-accounts-table-row.module.scss';
import globalStyles from './style.module.scss';
import { clsx } from 'clsx';
import { useContext } from 'react';
import { UserContext } from '../../context/user/UserContext';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { ReactComponent as ExternalLinkIcon } from '../../assets/images/svg/external-link.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/svg/trash.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/svg/info-icon.svg';
import CopyToClipboardTooltip from '../../components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';

// address, chainTicker, description, externalUrl, externalProviderName
const ExternalConnectedAccountsTableRow = ({ accountData, tableIsExpandable = true, handleDisconnect }) => {
  const { providersInfo } = useContext(UserContext);
  const providerIcon = providersInfo.find(provider => provider.account === `onchain/${accountData.chainTicker}`)?.icon;
  const externalAccountsInfo = `This account's provider is ${accountData?.providerLabel},
  which is not supported yet. Please use the provided link to ${accountData.externalProviderName} to view more details
  about this account.`;

  return <div
    className={clsx(styles.containerBase, tableIsExpandable && styles.containerExpandable, !tableIsExpandable && styles.container, `TEST-connected-accounts-table-row-${accountData?.description}`)}>
    {tableIsExpandable && <div className={clsx(globalStyles.centered, styles.centeredHorizontally)}>
      <CopyToClipboardTooltip tooltipData={externalAccountsInfo} openOnHover>
        <div className={clsx(styles.infoIconContainer, styles.lowOpacity)}>
          <InfoIcon />
        </div>
      </CopyToClipboardTooltip>
    </div>}

    <div className={globalStyles.centered}>
      <div className={styles.externalLinkContainer}>
        <div className={styles.icon}>
          <ExternalLinkIcon />
        </div>
        <a href={accountData.externalUrl} target='_blank' className={styles.link} rel='noreferrer'>
          {accountData.externalProviderName}
        </a>
      </div>
    </div>

    <div className={globalStyles.centered}>
      <div className={styles.lowOpacity}>
        Crypto Wallet
      </div>
    </div>

    <div className={globalStyles.centered}>
      <div className={clsx(globalStyles.iconWithSymbolContainer, styles.lowOpacity)}>
        {providerIcon && <div className={globalStyles.imgIconContainer}>
          <img src={providerIcon} alt={`onchain/${accountData.chainTicker}`}/>
        </div>}
        {accountData.chainTicker}
      </div>
    </div>

    <span className={globalStyles.centered}>
      <div className={globalStyles.address}>
        <CopyToClipboardWithIcon copyValue={accountData.address} tooltipDisplayedData={accountData.address} iconClasses={styles.lowOpacity}>
          <div className={styles.lowOpacity}>
            {accountData?.description}
          </div>
        </CopyToClipboardWithIcon>
      </div>
    </span>

    {tableIsExpandable && <div className={globalStyles.centered}>
      <div className={clsx(globalStyles.disconnectIconContainer, styles.lowOpacity)}>
        <span className={globalStyles.disconnectIcon} onClick={handleDisconnect}>
          <TrashIcon />
        </span>
      </div>
    </div>}
  </div>;
};

export default ExternalConnectedAccountsTableRow;
