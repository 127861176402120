import axios from 'axios';
import { axiosErrorHandler, endpoints } from '../../services/api/axios';
import { formatNumber } from '../../utils/number-formatter';
import { ReactComponent as CheckMark } from 'assets/images/svg/check-mark.svg';
import { ReactComponent as Cross } from 'assets/images/svg/cross.svg';
import PlanButton from './PlanButton';
import { getPlanImgSrc, numberToPrice } from '../../utils/billing/plan-formatter';
import appRoutes from '../../routes/routes';
import { frequencyToPeriod, getPreferredCurrency, maybePlural, parseNameWithSentiment } from '../../utils/billing/utils';
import { useMemo } from 'react';
import { clsx } from 'clsx';
import { toast } from 'react-hot-toast';

const PlanAttribute = ({ attribute, isPositive, contactOnly }) => {
  if (!attribute.length) {
    return;
  }

  return <div className='plan-feature-container'>
    <div className={clsx('plan-feature-icon-container',  !contactOnly && 'sentiment-active')}>
      {isPositive ? <CheckMark /> : <Cross />}
    </div>
    <div className={clsx('plan-feature-name', contactOnly && 'plan-feature-text-disabled')}>
      {attribute}
    </div>
  </div>;
};

const PlanFeatures = ({ plan }) => {

  const baseAttributes = useMemo(() => {
    const commonData = {
      isPositive: true,
      contactOnly: plan.contact_only
    };
    if (plan.contact_only) {
      return ['Projects', 'Credits', 'Clients'].map(attributeName => ({
        attribute: `Custom number of ${attributeName}`,
        ...commonData
      }));
    }
    return [
      { attribute: maybePlural('Project', plan.projects !== 1) },
      { attribute: `${formatNumber(plan.credits)} Credits` },
      { attribute: `up to ${formatNumber(plan.users)} Clients` }
    ].map(attributeData => ({
      ...attributeData,
      ...commonData
    }));
  }, [plan]);

  return (
    <div>
      {baseAttributes.map((attributeData, idx) => {
        return <PlanAttribute
          {...attributeData}
          key={idx}
        />;
      })}

      {(plan?.extra_benefits ?? []).map((benefit, idx) => {
        const { name, isPositive } = parseNameWithSentiment(benefit);
        return <PlanAttribute
          attribute={name}
          isPositive={isPositive}
          contactOnly={plan.contact_only}
          key={idx}
        />;
      })}
    </div>
  );
};

const SpecificPlanCard = ({ plan, frequency, preferredCurrency }) => {

  const priceInfo = plan.prices.find((priceInfo) => priceInfo.period === frequencyToPeriod(frequency))
    || plan.prices[0];

  const handleBookCall = () => {
    window.location.href = appRoutes.external.contact;
  };

  const handlePlanButtonClick = (priceId) => {
    const getGeneratedUrl = async () => {
      try {
        const response = await axios.get(`${endpoints.BILLING_URL}/subscribe`, {
          params: {
            price_id: priceId,
            success_url: `${window.location.origin}/billing/manage-plan?payment-state=success`,
            cancel_url: `${window.location.origin}/billing/manage-plan?payment-state=fail`
          }
        });
        return response?.data;
      } catch (err) {
        try {
          const response = await axios.get(`${endpoints.BILLING_URL}/client-portal`, {
            params: {
              back_url: `${window.location.origin}/billing`
            }
          });
          return response?.data;
        } catch (error) {
          toast.error(axiosErrorHandler(error));
        }
      }
    };
    getGeneratedUrl().then((url) => {
      window.location = url;
    });
  };

  return (
    <div className={clsx(
      'plan-card-container',
      plan.contact_only && 'plan-card-container-contact-only',
      plan.popular && 'plan-card-container-most-popular'
    )}>

      {plan.popular && <div className='most-popular-badge'>Most popular</div>}

      <div className='plan-card-name-container'>
        <img
          src={getPlanImgSrc(plan)}
          alt='plan-icon'
        />
        <div className='plan-card-name-text'>
          {plan.name}
        </div>
      </div>

      {plan.contact_only &&
        <div className='info-box'>
          <div className='contact-only-message'>Do you need more?</div>
          <div className='contact-only-message'>Let us know!</div>
        </div>
      }

      {!plan.contact_only && !priceInfo &&
        <div className='info-box price-info-box'>
          <span className='plan-card-price'>{numberToPrice(0, preferredCurrency)}</span>
          <span className='plan-card-frequency'>{frequency}</span>
        </div>
      }

      {!plan.contact_only && priceInfo &&
        <div className='info-box price-info-box'>
          <span className='plan-card-price'>{numberToPrice(priceInfo.price, priceInfo.currency)}</span>
          <span className='plan-card-frequency'>{frequency}</span>
        </div>
      }

      <div>
        <PlanButton
          plan={plan}
          priceId={priceInfo?.id}
          onClick={handlePlanButtonClick}
        />
      </div>

      <div>
        <PlanFeatures plan={plan}/>
      </div>

      <div className={`book-a-call ${plan.contact_only ? 'book-a-call-inactive' : 'book-a-call-active'}`} onClick={handleBookCall}>
        Need help? Book a call →
      </div>

    </div>
  );
};

const PlanSelectorCards = ({ plans, frequency }) => {
  return (
    <div className='plan-selector-cards-container'>
      {plans.map((plan, idx) => {
        return <SpecificPlanCard plan={plan} key={idx} frequency={frequency} preferredCurrency={getPreferredCurrency(plans)}/>;
      })}
    </div>
  );
};

export default PlanSelectorCards;
