import styles from '../style.module.scss';
import MainButton from 'components/buttons/MainButton';
import Select from 'components/select/Select';
import { useMemo, useState } from 'react';
import { ReactComponent as BoltGreenIcon } from 'assets/images/svg/boltGreenIcon.svg';
import { ROLES } from '../../../utils/roles';


const EditOrInviteUserModalContent = ({ headerIcon, title, handleSubmit, isEdit = false, name = '', email = '', role = '' }) => {
  const [userData, setUserData] = useState({ name, email, role });
  const isButtonDisabled = useMemo(
    () => Object.values(userData).some(userDetail => userDetail === ''),
    [userData]
  );
  const userRoles = Object.values(ROLES)
    .filter(role => role !== ROLES.OWNER)
    .map(role => ({ label: role }));

  const handleInputChange = (value, name) => {
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const handleSelectChange = (selectedRole) => {
    handleInputChange(selectedRole.label, 'role');
  };

  const handleFormSubmit = (e) => {
    const form = e.target.closest('form');
    if (!form.reportValidity()) {
      return;
    }
    e.preventDefault();
    handleSubmit(userData);
  };

  return <div className={styles.inviteModal}>
    <div className={styles.modalTitle}>
      <div className={styles.icon}>
        {headerIcon}
      </div>
      <span className={styles.title}>
        {title}
      </span>
    </div>
    <form className={styles.form}>
      <input
        type='name'
        name='name'
        onChange={(e) => handleInputChange(e.target.value, 'name')}
        value={userData.name}
        placeholder='Name'
        className={styles.formInput}
      />
      <input
        type='email'
        name='email'
        disabled={isEdit}
        onChange={(e) => handleInputChange(e.target.value, 'email')}
        value={userData.email}
        placeholder='Email'
        className={styles.formInput}
      />
      <Select
        options={userRoles}
        disabled={role === ROLES.OWNER}
        name='role'
        placeholder='Choose user role'
        selectClassName={styles.inviteUsersSelect}
        value={userData.role}
        onOptionClick={handleSelectChange}
      />
      <MainButton
        type='submit'
        variant='button-purple'
        label={isEdit ? 'SAVE CHANGES' : 'INVITE USER'}
        icon={<BoltGreenIcon/>}
        onClick={handleFormSubmit}
        disabled={isButtonDisabled}
      />
    </form>
  </div>;
};

export default EditOrInviteUserModalContent;
