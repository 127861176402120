import { getChartParsedData, getMaxValueFromData, getPercentageValue } from '../ChartHelper.js';
import { useMemo } from 'react';
import { NoData } from 'components/no-data-container/NoData';
import { useNavigate, useParams } from 'react-router-dom';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';

export const LocationsWidgetVerticalChart = ({ widget }) => {
  const filteredData = widget.data.filter(obj => obj.country !== 'Unknown');
  const parsed = useMemo(() => getChartParsedData(filteredData, 'txs_count'), [widget]);
  const maxValue = getMaxValueFromData(parsed, 'txs_count');
  const navigate = useNavigate();
  const { id } = useParams();

  const isIcon = (el) => el.icon !== 'https://api-dev.blockmate.io/v1/locations-meta/location/icon?code=Unknown';
  const isCountryName = el => el.country !== 'Unknown';

  return (
    <div className='vertical-bar-chart-wrapper'>
      {parsed.length === 0 ? (<NoData>
        <MainButton
          variant='button-no-data'
          label='IMPORT CLIENTS'
          icon={<UsersIcon/>}
          onClick={() => navigate(`/my-projects/${id}/clients`)}
        />
      </NoData>)
        : (parsed.map(el => (
          <div className='bar-wrapper' key={el.country}>
            <div className='bar-label'>
              <div className='label'>
                {isCountryName(el) ? el.country : 'Worldwide'}
              </div>
              {isIcon(el)
                ? (
                  <img src={el.icon} className='country-icon' alt=''/>
                )
                : (
                  <div className='country-icon'>
                    <EarthIcon/>
                  </div>
                )
              }
            </div>
            <div className='bar-line-wrapper'>
              <div className='bar-line' style={{
                backgroundColor: el.color,
                width: getPercentageValue(el.txs_count, maxValue),
              }}>
                <div className='bar-number'>{el.txs_count}</div>
              </div>
            </div>
          </div>
        )))}
    </div>
  );
};
