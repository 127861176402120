import { useEffect, useState } from 'react';
import { valueExists } from '../../utils/common';
import styles from '../../pages/customer-details/style.module.scss';
import { clsx } from 'clsx';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import { InfoLabel, AlertLabel } from './Labels';
import { getTextClassByRisk } from '../../utils/riskLevels';
import CopyToClipboardWithIcon from '../copy-to-clipboard-with-icon/CopyToClipboardWithIcon';

const TxMovementInfo = ({ movement, ownedLabel, accountInfo, txOwnProvider, hideValue = false }) => {
  const [shortDisplayName, setShortDisplayName] = useState('');
  const [movementLabel, setMovementLabel] = useState();
  const [tooltipDisplayedData, setTooltipDisplayedData] = useState('');
  const [copyValue, setCopyValue] = useState('');
  const [textClassByRisk, setTextClassByRisk] = useState('text-risk-low');

  useEffect(() => {
    setTextClassByRisk(getTextClassByRisk(movement.risk ?? 0));
  }, [movement]);

  useEffect(() => {
    if (!movement) {
      return;
    }
    let fullDisplayName = movement.address;
    let tooltipData = movement.address;
    if (accountInfo) {
      if (movement.owned) {
        fullDisplayName = ownedLabel;
        if (accountInfo.description) {
          fullDisplayName = accountInfo.description;
          tooltipData = `${accountInfo.description}`;
          if (valueExists(movement.address)) {
            tooltipData += `(${movement.address})`;
          }
        }
      } else if (valueExists(movement.name)) {
        fullDisplayName = movement.name;
        tooltipData = `${movement.name} (${movement.address})`;
      }
    }
    let movementCopyValue = movement.address;
    if (!valueExists(movement.address) && movement.owned) {
      movementCopyValue = accountInfo?.description ?? '';
    }
    const shortName = fullDisplayName.length > 15 ? `${fullDisplayName.slice(0, 15)}...` : fullDisplayName;
    setShortDisplayName(shortName);
    setTooltipDisplayedData(tooltipData);
    setCopyValue(movementCopyValue);

    let label = null;
    if (movement.category) {
      label = <AlertLabel category={movement.category} risk={movement.risk ?? 0} />;
    }
    if (movement.owned) {
      label = <InfoLabel text={txOwnProvider ?? 'owned'} />;
    }
    setMovementLabel(label);
  }, [movement, ownedLabel, accountInfo, txOwnProvider]);

  if (!movement) {
    return;
  }

  return <span className={styles.transaction}>
    <span className={clsx(movement.owned && styles.owned, (movement.category && !movement.owned) && textClassByRisk, styles.name)}>
      <div className={styles.labeledAddressContainer}>
        <CopyToClipboardWithIcon displayText={tooltipDisplayedData} copyValue={copyValue} tooltipDisplayedData={tooltipDisplayedData}>
          {shortDisplayName}
        </CopyToClipboardWithIcon>
        {movementLabel}
      </div>
    </span>
    <span className={styles.valueInfo}>{hideValue ? '?' : movement.value.toFixed(4)} {movement.currency_symbol}</span>
    {(movement.converted_currency !== null && !hideValue) && (
      <span
        className={styles.valueInfo}>{numberToPrice(movement.converted_value, movement.converted_currency, 2)}</span>
    )
    }
  </span>;
};

export default TxMovementInfo;
