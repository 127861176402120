import axios from 'axios';
import { axiosErrorHandler } from './axios';
import { toast } from 'react-hot-toast';

const getExchangeRates = async (pairs, apiUrl, userJwt) => {
  try {
    const response = await axios.get(
      '/v1/exchangerate/current',
      {
        baseURL: apiUrl,
        headers: {
          'Authorization': `Bearer ${userJwt}`,
        },
        params: {
          pairs: pairs.join(','),
        },
      }
    );
    const data = response?.data ?? [];
    const rates = {};
    data.forEach(rateInfo => rates[rateInfo.currency_pair] = rateInfo?.rate);
    return rates;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error getting exchange rates, displayed values might be incorrect'));
    return {};
  }
};

export default getExchangeRates;
