import { useContext, useRef } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/user/UserContext';
import {
  addMisttrackRiskToTxs,
} from '../../utils/misttrack';
import { valueExists } from '../../utils/common';
import { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';
import { axiosErrorHandler } from '../api/axios';


const useLoadNextTxsPage = ({
  userJWT,
  useMisttrackData,
  pageSize = 50,
}) => {
  const LAST_PAGE_CURSOR = '';
  const { getConfig, companyCurrency } = useContext(UserContext);

  const txsPageCursorRef = useRef(null);

  const loadNextPage = async (filters) => {
    return loadNextPageInternal(filters, txsPageCursorRef.current);
  };

  const loadNextPageInternal = async (filters, currentCursor) => {
    if (!hasNextPage() || !userJWT) {
      return {
        nextPage: [],
        loadError: undefined,
      };
    }

    let response;

    const params = {
      page_size: pageSize,
      currency: companyCurrency ?? 'usd',
      cursor: currentCursor,
      risk2: 1,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
        txTypesFilterParam,
        txRisksFilterParam,
        minPriceFilterParam,
        maxPriceFilterParam
      } = filters;

      if (valueExists(accountsFilterParam)) {
        params['account-filter'] = accountsFilterParam;
      }
      if (valueExists(digitalAssetsFilterParam)) {
        params.currencies = digitalAssetsFilterParam;
      }
      if (valueExists(txTypesFilterParam)) {
        params.types = txTypesFilterParam;
      }
      if (valueExists(txRisksFilterParam)) {
        params.risks = txRisksFilterParam;
      }
      if (valueExists(minPriceFilterParam)) {
        params['min-price'] = minPriceFilterParam;
      }
      if (valueExists(maxPriceFilterParam)) {
        params['max-price'] = maxPriceFilterParam;
      }
    }

    try {
      response = await axios.get('/v1/aggregate/transactions',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJWT}`,
          },
          params
        }
      );
    } catch (error) {
      return handleLoadError(error);
    }

    return processResponseData(response?.data);
  };

  const handleLoadError = (error) => {
    if (error?.response?.status === 0 && error?.request) { // In case of 504, no response nor status are available
      return {
        nextPage: [],
        loadError: 'Request is addressing too much data. Try reducing filters usage.',
      };
    } else {
      return {
        nextPage: [],
        loadError: axiosErrorHandler(error, 'Server error while loading transactions. Please try again later'),
      };
    }
  };

  const processResponseData = (data) => {
    const { transactions, page_cursor, risk2: misttrackRiskInfo } = data || {};

    if (transactions && page_cursor !== null) {
      txsPageCursorRef.current = page_cursor;

      if (!useMisttrackData) {
        return {
          nextPage: transactions,
          loadError: undefined,
        };
      }

      const mergedMisttrackRiskDetail = Object.values(misttrackRiskInfo ?? {}).reduce((acc, singleAccountInfo) => {
        const thisAccountRiskDetail = singleAccountInfo?.risk_result?.risk_detail ?? [];
        return [...acc, ...thisAccountRiskDetail];
      }, []);

      const txsWithCustomRisk = addMisttrackRiskToTxs(transactions, mergedMisttrackRiskDetail);

      return {
        nextPage: txsWithCustomRisk,
        loadError: undefined,
      };
    }
  };

  const hasNextPage = () => {
    return txsPageCursorRef.current !== LAST_PAGE_CURSOR;
  };

  const reset = () => {
    txsPageCursorRef.current = null;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS, filterTypes.PRICE, filterTypes.TYPES];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

export default useLoadNextTxsPage;
