import styles from './style.module.scss';
import { clsx } from 'clsx';

const RiskScoreOverviewTableRow = ({ title, value, identificationKey }) => {
  return (
    <div className={styles.tableRow}>
      <span className={styles.description}>{title}</span>
      <span className={clsx(styles.value, `TEST-${identificationKey}`)}>{value}</span>
    </div>
  );
};

export default RiskScoreOverviewTableRow;
