import { useContext } from 'react';
import { UserContext } from 'context/user/UserContext';
import PageMainContainer from 'components/page-main-container/PageMainContainer';
import ResetPasswordForm from 'components/AuthForms/ResetPasswordForm';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';
import yellowWaves from 'assets/images/NFT/yellow-waves.jpg';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as RefreshIcon } from 'assets/images/svg/refresh.svg';
import authService from 'services/auth-services/auth.service';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from 'services/api/axios';

function ResetPasswordPage() {
  const { external } = appRoutes;
  const { emailSent, resendEmail, resendSlug } = useContext(UserContext);
  const { requestPasswordReset } = authService;

  const handleResendEmail = async() => {
    try {
      await requestPasswordReset(resendEmail, resendSlug);
      toast.success('Email sent');
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error resetting password. Please try again later'));
    }
  };

  return (
    <>
      <PageMainContainer nftBgImg={yellowWaves}>
        <div className='content'>
          <div className='form-wrapper'>
            {emailSent ? (
              <>
                <FormHeader
                  title='Email Sent'
                  titleText={`We've sent you an email to ${resendEmail}. Please click on the button 'Reset password’ to create a new one.`}
                />
                <MainButton
                  onClick={handleResendEmail}
                  variant='button-gray'
                  label='RESEND EMAIL'
                  icon={<RefreshIcon />}
                />
              </>
            ) : (
              <>
                <FormHeader
                  title='Reset Password'
                  titleText="Give us your email, and we'll send you a link to reset your password."
                />
                <ResetPasswordForm />
              </>
            )}
            <FormBottomLink
              linkContent='Need help? Contact support →'
              linkPath={external.contact}
              externalLink={true}
            />
          </div>
        </div>
      </PageMainContainer>
    </>
  );
}

export default ResetPasswordPage;
