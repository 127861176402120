import styles from './style.module.scss';
import TableButton from 'components/buttons/table-button/TableButton';
import { ReactComponent as UserIcon } from 'assets/images/svg/users-secondary.svg';
import { ReactComponent as NextIcon } from 'assets/images/svg/next-secondary-icon.svg';

const UsersManagementTableHeader = ({ handleInviteClick, handleSettingsClick }) => (
  <div className={styles.tableHeader}>
    <TableButton label='Invite new user' icon={<UserIcon/>} onClick={handleInviteClick}/>
    {/*<TableButton label='SSO Settings' icon={<NextIcon/>} onClick={handleSettingsClick}/>*/}
  </div>
);

export default UsersManagementTableHeader;
