import localStyles from './aml-quick-check.module.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../context/user/UserContext';
import axios from 'axios';
import { clsx } from 'clsx';
import styles from '../customer-details/style.module.scss';
import { ReactComponent as SearchQuickIcon } from '../../assets/images/svg/search-quick.svg';
import Loader from '../../components/loaders/loader/Loader';
import { genesisTimestampByChain, getChainByAddress } from '../../utils/addresses';
import ConnectedAccounts from '../customer-details/ConnectedAccounts';
import RiskScoreOverview from '../customer-details/RiskScoreOverview';
import TransactionRiskCategories from '../../components/transaction-risk-categories/TransactionRiskCategories';
import { dateStrOrTimestampToTimestamp } from '../../utils/date-formatter';
import NoAccountMessage from '../../components/explorer-like/NoAccountMessage';
import {
  getMisttrackCategories,
  getRiskFromMisttrackCategories
} from '../../utils/misttrack';
import { getTemporaryJwt } from '../../services/temporary-user/temporary-user-service';
import AddressInput from '../../components/address-input/AddressInput';
import getExchangeRates from '../../services/api/exchange-rates';
import { handleQuickRiskError } from '../../utils/quick-risk';

const AMLQuickCheck = () => {
  const { explorerProject } = useOutletContext();
  const [connectedAddress, setConnectedAddress] = useState('');
  const [currentChain, setCurrentChain] = useState('btc');
  const { getConfig, companyCurrency } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState();

  const getRiskData = async (chain, address, userJwt) => {
    try {
      const response = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          },
          params:{
            chain: chain,
            address: address,
          },
        }
      );
      const projectCurrencyPair = `usd/${companyCurrency}`.toLowerCase();
      const exchangeRates = await getExchangeRates([projectCurrencyPair], getConfig('api_url'), userJwt);
      const exchangeRate = exchangeRates[projectCurrencyPair];
      const responseData = response.data;
      const balanceFromOverview = responseData?.overview?.balance ?? 0;
      if (balanceFromOverview > 0) {  // Fix wrong responses from Misttrack
        const updatedBalance = responseData.balance.map(balanceEntry => {
          if (String(balanceEntry?.contract_ticker_symbol).toLowerCase() === chain.toLowerCase()) {
            if (balanceEntry.balance !== 0) {
              return balanceEntry;
            }
            return {
              ...balanceEntry,
              balance: balanceFromOverview * Math.pow(10, balanceEntry?.contract_decimals ?? 0),
            };
          }
          return balanceEntry;
        });
        responseData.balance = updatedBalance;
      }
      const transformedData = {
        ...responseData,
        balance: responseData.balance.map(balanceEntry => {
          if (balanceEntry?.quote === 0 && balanceEntry.balance > 0) {
            balanceEntry.quote = balanceEntry.balance * (balanceEntry?.quote_rate ?? 0) / Math.pow(10, balanceEntry?.contract_decimals ?? 0);
          }
          const withUpdatedQuote = {
            ...balanceEntry,
            quote: (balanceEntry?.quote ?? 0) * exchangeRate,
          };
          return withUpdatedQuote;
        }),
      };
      return transformedData;
    } catch (error) {
      setAccount(undefined);
      handleQuickRiskError(error);
    }
  };

  const handleConnect = useCallback(async () => {
    setIsLoading(true);
    const userJwt = await getTemporaryJwt(explorerProject.id);
    const riskData = await getRiskData(currentChain, connectedAddress, userJwt);
    if (!riskData) {
      setIsLoading(false);
      return;
    }
    const balanceData = {
      accounts: [
        {
          account_id: 'any_id',
          description: `${currentChain.toUpperCase()}-01`,
          wallet: connectedAddress,
          type: 'crypto_wallet',
          balance: (riskData?.balance ?? []).map(balanceEntry => ({
            currency: balanceEntry?.contract_ticker_symbol ?? '?',
            currency_name: balanceEntry?.contract_name ?? '?',
            currency_symbol: balanceEntry?.contract_ticker_symbol ?? '?',
            value: (balanceEntry?.balance ?? 0) / (10 ** (balanceEntry?.contract_decimals ?? 0)),
            converted_currency: companyCurrency,
            converted_value: balanceEntry?.quote ?? 0,
            image_url: balanceEntry?.logo_urls?.token_logo_url ?? balanceEntry?.logo_url,
          })),
          url: `onchain/${currentChain}`,
          name: currentChain.toUpperCase(),
        }
      ]
    };

    const overview = riskData?.overview;

    const pair = `usd/${companyCurrency}`.toLowerCase();
    const exchangeRates = await getExchangeRates([pair], getConfig('api_url'), userJwt);
    const exchangeRate = exchangeRates[pair];

    const highRiskLabels = ['sanctioned', 'malicious', 'mixer', 'high_risk', 'phishing', 'ransom'];
    const riskDetail = riskData?.risk_result?.risk_detail ?? [];
    let highRiskVolume = 0, mediumRiskVolume = 0;
    riskDetail.forEach(entry => {
      if (highRiskLabels.includes(entry?.type)) {
        highRiskVolume += entry?.volume ?? 0;
      } else {
        mediumRiskVolume += entry?.volume ?? 0;
      }
    });
    const totalReceived = exchangeRate === undefined ? '?' : overview?.total_received * exchangeRate ?? 0;
    const totalSent = exchangeRate === undefined ? '?' : overview?.total_spent * exchangeRate ?? 0;
    const totalVolume = totalReceived + totalSent;
    highRiskVolume = Math.min(totalVolume, highRiskVolume);
    mediumRiskVolume = Math.max(0, Math.min(totalVolume - highRiskVolume, mediumRiskVolume));
    const lowRiskVolume = Math.max(0, totalVolume - highRiskVolume - mediumRiskVolume);

    // Uncomment if you want a number of risk txs as well (and also uncomment a snippet a bit below)
    // const { medium: mediumRiskTxs, high: highRiskTxs } = (riskData?.risk_result?.risk_detail ?? []).reduce((acc, entry) => {
    //   const levels = ['medium', 'high'];
    //   for (const level of levels) {
    //     if (String(entry?.type).includes(level)) {
    //       acc[level] += 1;
    //     }
    //   }
    //   return acc;
    // }, { medium: 0, high: 0 });
    // const lowRiskTxs = Math.max(0, (overview?.txs_count ?? 0) - mediumRiskTxs - highRiskTxs);

    // There could be timestamp or date string in response, so we always convert it to timestamp
    const chainTicker = getChainByAddress(connectedAddress)?.ticker;
    const minTimestamp = genesisTimestampByChain[chainTicker ?? 'btc'];
    const firstSeen = dateStrOrTimestampToTimestamp(overview?.first_seen, minTimestamp);
    const lastSeen = dateStrOrTimestampToTimestamp(overview?.last_seen, minTimestamp);

    const riskCategories = getMisttrackCategories(riskData?.risk_result ?? {});
    const customRisk = getRiskFromMisttrackCategories(riskCategories);

    const customerDetails = {
      first_tx: firstSeen,
      last_tx: lastSeen,
      age: Math.floor(((new Date()).getTime() / 1000 - firstSeen) / (60 * 60 * 24)),
      total_received: totalReceived,
      total_sent: totalSent,
      total_volume: totalVolume,
      high_risk_volume: highRiskVolume,
      medium_risk_volume: mediumRiskVolume,
      low_risk_volume: lowRiskVolume,
      currency: companyCurrency,
      // low_risk_tx_count: lowRiskTxs,
      // medium_risk_tx_count: mediumRiskTxs,
      // high_risk_tx_count: highRiskTxs,
      last_location: 'None',
      risk_countries: '',
      risk: customRisk,
      tx_count: overview?.txs_count ?? 0,
      received_txs: overview?.received_txs_count ?? 0,
      sent_txs: overview?.spent_txs_count ?? 0,
      country: 'Unknown',
      non_identified_txs: undefined,
    };

    setAccount({
      chain: currentChain,
      address: connectedAddress,
      data: riskData,
      balanceData,
      customerDetails,
    });
    setConnectedAddress('');
    setIsLoading(false);
  }, [connectedAddress, account, currentChain]);

  // disconnect
  const handleDisconnect = () => setAccount(undefined);

  // on first load wait for context
  useEffect(() => {
    if (explorerProject) {
      setIsLoading(false);
    }
  }, [explorerProject]);

  if (isLoading) {
    return <Loader />;
  }

  return <div className='page-content-container'>
    <div className={clsx(styles.customers, 'explorer-container')}>

      <AddressInput
        connectedAddress={connectedAddress}
        setConnectedAddress={setConnectedAddress}
        currentChain={currentChain}
        setCurrentChain={setCurrentChain}
        handleConnect={handleConnect}
        buttonLabel={'CHECK ACCOUNT'}
        chainFilter={['btc', 'eth', 'bnb', 'arbitrum', 'sol', 'trx']}
      />

      {!account && <div className={localStyles.noAccountInfo}>
        <NoAccountMessage
          icon={<SearchQuickIcon />}
          title='Quickly explore accounts'
          description={
            'Please paste address above in the input field to search and explore crypto accounts. ' +
            'Account\'s chain will be automatically detected.'
          }
        />
      </div>}
      {account && <div className={localStyles.tablesContainer}>
        {account?.balanceData && <ConnectedAccounts
          singleAccountMode={true}
          balanceData={account.balanceData}
          reducedInfo
          expandable={true}
          currency={companyCurrency ?? 'USD'}
          canDisconnect={true}
          handleDisconnect={handleDisconnect}
        />}
        {account?.customerDetails && <RiskScoreOverview
          customerDetails={account.customerDetails}
          closedLoop={{
            closed_loop: false,
            external_onchain_incoming: true,
          }}
          currency={companyCurrency ?? 'USD'}
        />}
        <TransactionRiskCategories riskResult={account?.data?.risk_result} />
      </div>}
    </div>
  </div>;
};

export default AMLQuickCheck;
