import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from 'services/api/axios';
import qs from 'qs';
import { toast } from 'react-hot-toast';

const createToken = async (projectId, tokenName) => {
  try {
    const response = await axios.post(
      getStaticPath('PROJECT_URL', `${projectId}/token?${qs.stringify({ name: tokenName })}`)
    );
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error creating the token. Please try again later'));
    return null;
  }
};

const getToken = async (projectId, tokenId) => {
  try {
    const response = await axios.get(
      getStaticPath('PROJECT_URL', `${projectId}/token/${tokenId}`)
    );
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error getting token. Please try again later'));
    return null;
  }
};

const listTokens = async (projectId) => {
  try {
    const response = await axios.get(getStaticPath('PROJECT_URL', `${projectId}/token`));
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error getting the list of tokens. Please try again later'));
    return null;
  }
};

const updateToken = async (projectId, tokenId, tokenName) => {
  try {
    const response = await axios.patch(
      getStaticPath('PROJECT_URL', `${projectId}/token/${tokenId}?${qs.stringify({ name: tokenName })}`)
    );
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error updating token. Please try again later'));
    return null;
  }
};

const deleteToken = async (projectId, tokenId) => {
  try {
    const response = await axios.delete(
      getStaticPath('PROJECT_URL', `${projectId}/token/${tokenId}`)
    );
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error deleting token. Please try again later'));
    return null;
  }
};

const createPublicToken = async (projectId, tokenName) => {
  try {
    const response = await axios.post(getStaticPath('PROJECT_URL', `${projectId}/public/token?${qs.stringify({ name: tokenName })}`));
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error creating public token. Please try again later'));
    return null;
  }
};

const getPublicTokens = async (projectId) => {
  try {
    const response = await axios.get(getStaticPath('PROJECT_URL', `${projectId}/public/token`));
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error getting public tokens. Please try again later'));
    return null;
  }
};

const deletePublicToken = async (projectId, tokenId) => {
  try {
    const response = await axios.delete(getStaticPath('PROJECT_URL', `${projectId}/public/token/${tokenId}`));
    return response.data;
  } catch (err) {
    toast.error(axiosErrorHandler(err, 'Error deleting public token. Please try again later'));
    return null;
  }
};

const apiTokenService = {
  createToken,
  getToken,
  listTokens,
  updateToken,
  deleteToken,
  createPublicToken,
  getPublicTokens,
  deletePublicToken,
};

export default apiTokenService;
