import { getChartParsedData, getMaxValueFromData, getPercentageValue } from '../ChartHelper.js';
import { useMemo } from 'react';
import { NoData } from 'components/no-data-container/NoData';
import { useNavigate, useParams } from 'react-router-dom';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';
import CopyToClipboardTooltip from 'components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';

export const VerticalChart = ({ widget, labelKey, countKey, withoutIcon, children, withCopyTooltip, unit }) => {
  const parsed = useMemo(() => getChartParsedData(widget.data, countKey), [widget]);
  const maxValue = getMaxValueFromData(parsed, countKey);

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className='vertical-bar-chart-wrapper'>
      {parsed.length === 0 ? (
        <NoData>
          <MainButton
            variant='button-no-data'
            label='IMPORT CLIENTS'
            icon={<UsersIcon/>}
            onClick={() => navigate(`/my-projects/${id}/clients`)}
          />
        </NoData>
      ) : (
        parsed.map(chart => (
          <div className='bar-wrapper' key={chart[labelKey]}>
            {withCopyTooltip ? (
              <CopyToClipboardTooltip
                tooltipDataValue={chart[labelKey]}
                tooltipData='Copy to clipboard'
                wrapperClassName='bar-label'
                copyValue={chart[labelKey]}
              >
                <div className='label'>
                  {chart[labelKey]}
                </div>
                {!withoutIcon &&
                        <div className='country-icon'>
                          <EarthIcon/>
                        </div>
                }
                {children}
              </CopyToClipboardTooltip>
            ) : (
              <div className='bar-label'>
                <div className='label'>
                  {chart[labelKey]}
                </div>
                {!withoutIcon &&
                      <div className='country-icon'>
                        <EarthIcon/>
                      </div>
                }
                {children}
              </div>
            )}
            <div className='bar-line-wrapper'>
              <div
                className='bar-line'
                style={{
                  backgroundColor: chart.color,
                  width: getPercentageValue(chart[countKey], maxValue),
                }}
              >
                <div className='bar-number'>
                  {`${chart[countKey].toFixed(0)} ${unit ? unit : ''}`}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
