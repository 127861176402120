import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from 'services/api/axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import useNotification from 'components/notification/UseNotification';

const UserProvider = ({
  provider,
  userId,
  providerIcon
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState('');
  const ref = useRef(null);
  const { id } = useParams();
  const showNotification = useNotification();

  const handleTooltipOpen = async () => {
    setIsOpen(true);
    setIsLoading(true);
    try {
      const { data } = await axios.get(getStaticPath('USERLIST_URL', `${id}/${userId}/addresses`), {
        params: {
          provider: provider,
        }
      });
      setAddresses(data.addresses);
    }
    catch (error) {
      toast.error(axiosErrorHandler(error, 'Error getting addresses'));
    }
    setIsLoading(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
      setAddresses('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className='provider-icon' onClick={handleTooltipOpen}>
      {providerIcon?.icon && <img className='provider-img' src={providerIcon.icon} alt=''/>}
      {isOpen &&
      <div ref={ref} className='provider-tooltip'>
        {isLoading
          ? <ClipLoader color='#d0d0d0'/>
          : (
            <>
              <span
                className='addresses-count'>{addresses[0]}
              </span>
              {addresses.length
                ? <CopyToClipboard text={addresses ?? ''} onCopy={() => showNotification('copyAdresses', 'success')}>
                  <span className='copy-to-clipboard'>Copy to clipboard</span>
                </CopyToClipboard>
                : (
                  <span>No address</span>
                )
              }
            </>
          )}
      </div>
      }
    </div>
  );
};

export default UserProvider;
