import { useContext, useState, useEffect } from 'react';
import { ApiContext } from 'context/api/ApiContext';
import apiTokenService from 'services/api-tokens-services';
import ApiKeysListHeading from 'components/ApiKeysList/ApiKeysListHeading';
import Loader from 'components/loaders/loader/Loader';
import { NoData } from 'components/no-data-container/NoData';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import useNotification from 'components/notification/UseNotification';
import ApiKeysPublicTableRow from './ApiKeysPublicTableRow';

function ApiKeysPublicListTable({ loading, setLoading, list, generateNewApiKey, setList }) {
  const [copiedApiToken, setCopiedApiToken] = useState(null);
  const { apiToken, setApiToken } = useContext(ApiContext);
  const { deletePublicToken } = apiTokenService;
  const showNotification = useNotification();

  const handleDeleteKey = async (project_id, token_id) => {
    setLoading(true);
    const deleteResult = await deletePublicToken(project_id, token_id);
    if (deleteResult !== null) {
      const filteredPublicApisList = list?.filter((el) => el.id !== token_id);
      setList(filteredPublicApisList);
      showNotification('deleteApiKey', 'success');
    }
    setLoading(false);
  };

  const handleCopyKey = (token_id) => {
    setCopiedApiToken(token_id);
    showNotification('copyApiKey', 'success');
  };

  useEffect(() => {
    return () => setApiToken(null);
  }, []);

  if (loading) return <Loader/>;

  if (!list.length) return (
    <NoData>
      <MainButton
        variant='button-no-data'
        label='CREATE NEW API KEY'
        icon={<UsersIcon/>}
        onClick={generateNewApiKey}
      />
    </NoData>
  );

  return (
    <>
      <ApiKeysListHeading publicKeysList/>
      <div className='api-keys-table-wrapper'>
        <div className='api-keys-body'>
          {list.map((token, index) => {
            return (
              <ApiKeysPublicTableRow
                key={token.id}
                token={token}
                index={index}
                copiedApiToken={copiedApiToken}
                apiToken={apiToken}
                handleCopyKey={handleCopyKey}
                handleDeleteKey={handleDeleteKey}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ApiKeysPublicListTable;
