import styles from './company-settings.module.scss';
import { useEffect, useState, useContext } from 'react';
import useNotification from '../../components/notification/UseNotification';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import Select from '../../components/select/Select';
import MainButton from '../../components/buttons/MainButton';
import { SUPPORTED_CURRENCIES } from '../../utils/project-currencies';
import { UserContext } from '../../context/user/UserContext';
import { toast } from 'react-hot-toast';

const CompanySettings = () => {
  const { companyCurrency, updateProfile } = useContext(UserContext);
  const showNotification = useNotification();
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (companyCurrency) {
      setCurrency(
        SUPPORTED_CURRENCIES.find(currencyInfo => currencyInfo.ticker === companyCurrency)
      );
    }
  }, [companyCurrency]);

  const handleSubmit = async () => {
    try {
      await axios.patch(
        getStaticPath('CLIENT_URL', 'update-company'),
        {
          currency: currency.ticker
        }
      );
      updateProfile();
      showNotification('companyUpdate', 'success');
    }
    catch (error) {
      toast.error(axiosErrorHandler(error, 'Error updating the company. Please try again later'));
    }
  };

  return <div className={styles.container}>
    <div className={styles.heading}>Company settings</div>
    <div className='project-settings-container'>
      {currency && <div className='single-setting-container'>
        <p className='project-settings-title'>
          Explorer currency
        </p>
        <Select
          options={SUPPORTED_CURRENCIES.map(currencyInfo => {
            return {
              label: currencyInfo.label
            };
          })}
          value={currency.label}
          onOptionClick={(clickedOption) => setCurrency(
            SUPPORTED_CURRENCIES.find(option => option.label === clickedOption.label)
          )}
        />
      </div>}
      <div className='single-setting-container'>
        <MainButton
          type='submit'
          variant='button-purple'
          label='SAVE CHANGES'
          icon={<BoltGreen/>}
          onClick={handleSubmit}
        />
      </div>
    </div>
  </div>;
};

export default CompanySettings;
