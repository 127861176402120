import styles from './deposits-admin.module.scss';
import React, { useContext, useState } from 'react';
import MainButton from '../../components/buttons/MainButton';
import { ReactComponent as BoltGreen } from '../../assets/images/svg/boltGreenIcon.svg';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../context/user/UserContext';
import { getTemporaryJwt } from '../../services/temporary-user/temporary-user-service';
import { useParams } from 'react-router-dom';
import { DEPOSITS_ADMIN_KEY } from '../debug/debug';
import { axiosErrorHandler } from '../../services/api/axios';

const DepositsAdmin = () => {
  const NETWORKS = [
    {
      key: 'ETHEREUM',
      label: 'Ethereum',
    },
    {
      key: 'BITCOIN',
      label: 'Bitcoin',
    },
    {
      key: 'TRON',
      label: 'Tron',
    }
  ];
  const CURRENCIES = [
    'BTC',
    'ETH',
    'TRX',
    'USDT',
  ];

  const depositsAdmin = localStorage.getItem(DEPOSITS_ADMIN_KEY) === 'ON';

  const { getConfig } = useContext(UserContext);
  const { id } = useParams();

  const [projectIdForPost, setProjectIdForPost] = useState('');
  const [txId, setTxId] = useState('');
  const [currency, setCurrency] = useState(CURRENCIES[0]);
  const [network, setNetwork] = useState(NETWORKS[0].key);
  const [amount, setAmount] = useState();

  const [projectIdForGet, setProjectIdForGet] = useState('');
  const [payouts, setPayouts] = useState([]);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleRegisterPayout = async () => {
    try {
      const jwt = await getTemporaryJwt(id);
      await axios.post(
        '/v1/exchange/deposit/merchant-payouts',
        {
          project_id: Number(projectIdForPost),
          transaction_id: txId,
          currency: currency,
          network: network,
          amount: amount,
        },
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        }
      );
      toast.success('Successfully registered the payout.');
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error registering merchant payout. Please try again later'));
    }
  };

  const getLatestPayouts = async () => {
    const params = {};
    if (projectIdForGet !== undefined && projectIdForGet !== '') {
      params.requested_project_id = projectIdForGet;
    }
    const jwt = await getTemporaryJwt(id);
    try {
      const response = await axios.get(
        '/v1/exchange/deposit/merchant-payouts',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          params
        }
      );
      setPayouts(response?.data);
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error getting payouts, please try again later'));
    }
  };

  const deletePayout = async (payoutId) => {
    setIsDeleting(true);
    const jwt = await getTemporaryJwt(id);
    try {
      await axios.delete(
        `/v1/exchange/deposit/merchant-payouts/${payoutId}`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
        }
      );
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error deleting merchant payout. Please try again later'));
    }
    await getLatestPayouts();
    setIsDeleting(false);
  };

  if (!depositsAdmin) {
    return <div></div>;
  }

  return <div className={styles.outerContainer}>
    <div className={styles.container}>
      <div className={styles.heading}>Register a new merchant payout</div>

      <div className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.label}>Merchant project ID:</div>
          <input type='text' value={projectIdForPost} onChange={e => setProjectIdForPost(e.target.value)}/>
        </div>

        <div className={styles.formRow}>
          <div className={styles.label}>Transaction ID:</div>
          <input type='text' value={txId} onChange={e => setTxId(e.target.value)}/>
        </div>

        <div className={styles.formRow}>
          <div className={styles.label}>Currency:</div>
          <select value={currency} onChange={e => setCurrency(e.target.value)}>
            {CURRENCIES.map(c => <option value={c} key={`currency-${c}`}>{c}</option>)}
          </select>
        </div>

        <div className={styles.formRow}>
          <div className={styles.label}>Network:</div>
          <select value={network} onChange={e => setNetwork(e.target.value)}>
            {NETWORKS.map(nw => <option value={nw.key} key={`network-${nw.key}`}>{nw.label}</option>)}
          </select>
        </div>

        <div className={styles.formRow}>
          <div className={styles.label}>Amount:</div>
          <input type='number' value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>

        <MainButton
          onClick={handleRegisterPayout}
          variant='button-purple'
          label='Register payout'
          icon={<BoltGreen />}
        />
      </div>

      <div className={styles.divider}></div>

      <div>
        <div className={styles.heading}>Get payouts for a project</div>
        <div className={styles.form}>
          <div className={styles.formRow}>
            <div className={styles.label}>Merchant project ID:</div>
            <input type='number' value={projectIdForGet} onChange={e => setProjectIdForGet(e.target.value)}/>
          </div>
          <MainButton
            onClick={getLatestPayouts}
            variant='button-purple'
            label='Get payouts'
            icon={<BoltGreen />}
          />
          <div className={styles.payoutsTable}>
            <div className={`${styles.payoutsTableRow} ${styles.tableHeader}`}>
              <div>Delete</div>
              <div>CreatedAt</div>
              <div>PayoutID</div>
              <div>ProjectID</div>
              <div>Amount</div>
              <div>Currency</div>
              <div>Network</div>
              <div>TransactionID</div>
            </div>
            {payouts.map(payout => <div className={styles.payoutsTableRow} key={payout.id}>
              {isDeleting ?
                <div>...</div> :
                <div className={styles.delete} onClick={() => deletePayout(payout.id)}>
                  X
                </div>
              }
              <div>{payout.created_at.split('.')[0].split('T').join(' ')}</div>
              <div>{payout.id}</div>
              <div>{payout.project_id}</div>
              <div>{payout.amount}</div>
              <div>{payout.currency}</div>
              <div>{payout.network}</div>
              <div>{payout.transaction_id}</div>
            </div>)}
            {payouts.length === 0 && <div>No entries</div>}
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default DepositsAdmin;
