import { useEffect, useState } from 'react';
import PageMainContainer from 'components/page-main-container/PageMainContainer';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import MainButton from 'components/buttons/MainButton';
import hyperloop from 'assets/images/NFT/hyperloop.jpg';
import authService from 'services/auth-services/auth.service';
import appRoutes from 'routes/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormHeader from 'components/form/form-header/FormHeader';
import { ReactComponent as SendIcon } from 'assets/images/svg/send-icon.svg';
import { axiosErrorHandler } from '../../../services/api/axios';

function ConfirmEmail() {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchParams] = useSearchParams();
  const { external, auth } = appRoutes;
  const { confirmUserEmail } = authService;
  const navigate = useNavigate();

  useEffect(() => {
    const emailConfirmation = async (token) => {
      try {
        setErrorMessage(null);
        await confirmUserEmail(token);
        setEmailConfirmed(true);
      }
      catch (error) {
        setEmailConfirmed(false);
        setErrorMessage(axiosErrorHandler(error, 'Error confirming email. Please try again later'));
      }
    };

    const tokenValue = searchParams.get('token');
    emailConfirmation(tokenValue);
  }, []);

  return (
    <PageMainContainer nftBgImg={hyperloop}>
      <div className='content'>
        <FormHeader
          title='Email Confirmation'
          titleText={
            emailConfirmed
              ? 'Congratulations!!! Your email has been confirmed. You can now log in to your Blockmate account.'
              : `Something went wrong. ${errorMessage}.`
          }
        />
        <MainButton
          variant='button-gray'
          onClick={() => navigate(auth.login)}
          label='BACK TO LOGIN'
          icon={<SendIcon />}
        />
        <FormBottomLink
          linkContent='Need help? Contact support →'
          linkPath={external.contact}
          externalLink={true}
        />
      </div>
    </PageMainContainer>
  );
}

export default ConfirmEmail;
