import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useContext } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';
import { axiosErrorHandler, getStaticPath } from 'services/api/axios';
import { useParams } from 'react-router-dom';

const AnalyticsSettings = ({
  name,
  withoutPeriod,
  handlePeriod
}) => {
  const {
    widgets,
    setWidgets
  } = useContext(WidgetContext);
  const { id } = useParams();

  const handleHide = async () => {
    try {
      await axios.post(
        getStaticPath('STATISTICS_URL','widget'),
        {
          name,
          timeframe: '7d',
          visible: 'false'
        },
        { params: { project_id: id } }
      );
      setWidgets(widgets.filter(item => item.name !== name));
    }
    catch (error) {
      toast.error(axiosErrorHandler(error, 'Something went wrong. Please try again later'));
    }
  };
  return (
    <div className='settings-modal'>
      <div className='settings-options'>
        {!withoutPeriod &&
          <>
            <div className='setting-option' data-period='7d' onClick={handlePeriod}>
              Last 7 days
            </div>
            <div className='setting-option' data-period='30d' onClick={handlePeriod}>
              Last 30 days
            </div>
            <div className='setting-option' data-period='90d' onClick={handlePeriod}>
              Last 90 days
            </div>
          </>
        }
        <div className='setting-option hide-widget' onClick={handleHide}>
          Hide widget
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSettings;
