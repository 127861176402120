import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../api/axios';
import { toast } from 'react-hot-toast';

export const getTemporaryJwt = async (projectId) => {
  try {
    const response = await axios.get(
      getStaticPath('APIAUTH_URL', `project/${projectId}/developer`),
      {
        params: {}
      }
    );
    return response.data.token;
  } catch (error) {
    toast.error(axiosErrorHandler(error, 'Error getting temporary auth token. Please try again later'));
  }
};
