import MainModal from 'components/modals/MainModal';
import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import { useState } from 'react';
import { getStaticPath } from 'services/api/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'routes/routes';
import MainButton from 'components/buttons/MainButton';
import useNotification from 'components/notification/UseNotification';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from 'services/api/axios';

const DeleteAccountModal = ({ handleClose }) => {
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { auth } = appRoutes;
  const showNotification = useNotification();

  const handleSubmit = () => {
    if (confirmationPassword.length < 8) {
      handleInvalidPassword();
      return;
    }

    handleAccountDelete();
  };

  const handleInvalidPassword = () => {
    if (confirmationPassword.length === 0) {
      showNotification('invalidPassword', 'error');
    } else {
      setIsError(true);
    }
  };

  const handleAccountDelete = async () => {
    try {
      await axios.delete(getStaticPath('CLIENT_URL'),
        { data: { password: confirmationPassword } }
      );
      showNotification('deleteAccount', 'success');
      localStorage.removeItem('user');
      navigate(auth.login);
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error deleting account. Please try again later'));
      setIsError(true);
    }
  };

  return (
    <MainModal handleClose={handleClose}>
      <div className='modal-content'>
        <div className='delete-project-modal-info-section'>
          <div className='trash-icon-container'>
            <Trash/>
          </div>
          <p className='modal-title'>
					Danger zone
          </p>
          <p className='modal-description'>
					Before proceeding, please confirm that you want to delete this account and all your data. Are you
					sure you want to continue?
          </p>
        </div>
        <div className='delete-project-modal-actions-section'>
          <input
            type='password'
            className='delete-modal-input'
            placeholder='Confirm with account password'
            onChange={(e) => setConfirmationPassword(e.target.value)}
            onClick={() => setIsError(false)}
          />
          {isError && <p className='input-alert'>Wrong password!</p>}
          <MainButton
            variant='button-error'
            onClick={handleSubmit}
            label='DELETE ACCOUNT'
            icon={<Trash />}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default DeleteAccountModal;
