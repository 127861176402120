import styles from './style.module.scss';
import RiskScore from 'components/risk-score/RiskScore';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import { getDescriptionInfoFromAccount } from '../../utils/common';
import { useEffect, useMemo, useState, useContext } from 'react';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { DEBUG_KEY } from '../debug/debug';
import { clsx } from 'clsx';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/svg/minus-icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/svg/trash.svg';
import AssetsBreakdownTable from './AssetsBreakdownTable';
import { UserContext } from '../../context/user/UserContext';

const ConnectedAccountsTableRow = ({
  accountData,
  riskData,
  reducedInfo = false,
  expandable = false,
  otherRowIsExpanded,
  onExpand,
  currency = 'USD',
  canDisconnect = false,
  handleDisconnect
}) => {
  const [balance, setBalance] = useState('-');
  const [expanded, setExpanded] = useState(false);
  const { providersInfo } = useContext(UserContext);
  const {
    tooltipDescription,
    shortDescription,
    copyValue
  } = getDescriptionInfoFromAccount(accountData, 12);
  const providerUrl = useMemo(() => {
    const tokens = accountData.url.split('/');
    return `${tokens[0]}/${tokens[1]}`;
  }, [accountData]);
  const providerIcon = useMemo(() => {
    const provider = providersInfo.find(provider => provider.account === providerUrl);
    return provider?.icon;
  }, [providerUrl]);
  const debug = localStorage.getItem(DEBUG_KEY) === 'ON';

  useEffect(() => {
    let sum = accountData.balance.map((b) => b.converted_value === undefined ? 0 : b.converted_value).reduce((acc, val) => acc+val, 0);
    let foundCurrency = accountData.balance.map((b) => b.converted_currency).reduce((acc, val) => {
      if(val !== undefined && val !== null && val !== '') {
        return val;
      }
      return acc;
    }, '-');

    if (foundCurrency !== '-') {
      setBalance(numberToPrice(sum, foundCurrency, 2));
    } else if (accountData.balance.length > 0) {
      setBalance(numberToPrice(0, currency, 2));
    }
  }, [accountData]);

  useEffect(() => {
    if (otherRowIsExpanded) {
      setExpanded(false); // So that only 1 row is expanded at a time
    }
  }, [otherRowIsExpanded]);

  const toggleExpand = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
      onExpand(); // To notify other rows to collapse
    }
  };

  const getRowClasses = () => {
    const classes = [];
    if (reducedInfo) {
      classes.push(styles.tableContentAmlDemo);
      if (expandable) {
        classes.push(styles.tableContentAmlDemoExpandable);
      }
    } else {
      classes.push(styles.tableContent);
      if (expandable) {
        classes.push(styles.tableContentExpandable);
      }
    }
    return clsx(...classes);
  };

  return (
    <div className={`TEST-connected-accounts-table-row-${accountData?.description}`}>
      <span className={getRowClasses()}>
        {expandable && <div className={clsx(styles.centered, styles.centeredBothWays)}>
          <div
            className={clsx(styles.smallIcon, styles.clickable)}
            onClick={toggleExpand}
          >
            {expanded ? <MinusIcon/> : <PlusIcon/>}
          </div>
        </div>}
        <span className={styles.centered}>{balance}</span>
        <span className={styles.centered}>{accountData.type.split('_').join(' ')}</span>
        <div className={styles.centered}>
          <div className={styles.iconWithSymbolContainer}>
            {providerIcon && <div className={styles.imgIconContainer}><img src={providerIcon} alt={providerUrl} /></div>}
            {accountData.name}
          </div>
        </div>
        <div className={styles.centered}>
          <span className={styles.address}>
            <CopyToClipboardWithIcon copyValue={copyValue} tooltipDisplayedData={tooltipDescription}>
              {shortDescription}
            </CopyToClipboardWithIcon>
          </span>
        </div>
        {!reducedInfo && <span className={styles.centered}>
          <div className={styles.verifiedIcon}>
            {accountData.verified
              ? <img alt='' src='/svg/check-mark.svg'/>
              : <img alt='' src='/svg/cross.svg'/>
            }
          </div>
        </span>}
        {!reducedInfo && <div className={styles.centered}>
          <span className={styles.riskScore}>
            <div>
              <RiskScore riskScore={riskData?.overall_risk ?? 0}/>
              <span className={styles.score}>{`${riskData?.overall_risk ?? '-'}/100`}</span>
            </div>
            {debug &&
              <p style={{ 'white-space': 'nowrap' }}>
                age_risk: {riskData?.age_risk}<br/>
                amount_risk: {riskData?.amount_risk}<br/>
                avg_am_risk: {riskData?.avg_am_risk}<br/>
                cat_risk: {riskData?.cat_risk}<br/>
                geo_risk: {riskData?.geo_risk}<br/>
                sanc_risk: {riskData?.sanc_risk}<br/>
                self_risk: {riskData?.self_risk}<br/>
                overall_risk: {riskData?.overall_risk}<br/>
              </p>
            }
          </span>
        </div>
        }
        <div className={styles.disconnectIconContainer}>
          {canDisconnect ?
            <span className={styles.disconnectIcon} onClick={() => handleDisconnect(accountData?.account_id ?? '')}>
              <TrashIcon />
            </span> :
            <span></span>
          }
        </div>
      </span>
      {expanded && <div className={styles.expandedSubrowContainer}>
        <div className={styles.verticalLine}></div>
        <div className={styles.expandedSubrowContentContainer}>
          <div className={styles.expandedSubrow}>
            <div className={styles.expandedAssetInfoContainer}>
              <AssetsBreakdownTable assets={accountData.balance} currency={currency}/>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ConnectedAccountsTableRow;
