import { valueExists } from '../../utils/common';
import * as Sentry from '@sentry/react';

export const endpoints = {
  CLIENT_URL: '/api/v1/client',
  ADMIN_URL: '/api/v1/admin',
  PROJECT_URL: '/api/v1/project',
  STATISTICS_URL: '/api/v1/statistics',
  USERLIST_URL: '/api/v1/userlist',
  APIAUTH_URL: '/api/v1/api_auth',
  ICONS_URL: '/api/v1/providers',
  BILLING_URL: '/api/v1/billing',
  ACCOUNT_URL: '/api/v1/account',
  CONTENTFUL_URL: 'https://cdn.contentful.com/spaces',
};

export const contentful = {
  ACCESS_TOKEN: 'UkjqBK-L3EQNPBRVYPtt97UmbCr37iYnUqhs7kNVIJY',
  SPACE_ID: 'd9phh1why0cv',
  ENTRY_ONBOARDING: '4tHO0ZMonbAxrivTh1JXk2',
  ENTRY_WIDGET: '31kkSnc5GQOrmd2QvoaZgT',
  ENTRY_HORIZONTAL: '3brWZ2TbYp9RTUdw7lG1ss'
};

const errorSearchDfs = (dataToSearchIn, errorKeysByPreference = []) => {
  if (!valueExists(dataToSearchIn)) {
    return undefined;
  }
  if (dataToSearchIn.constructor === Array) {
    for (const entry of dataToSearchIn) {
      const maybeErrorMessage = errorSearchDfs(entry, errorKeysByPreference);
      if (valueExists(maybeErrorMessage)) {
        return maybeErrorMessage;
      }
    }
  } else if (typeof dataToSearchIn === 'object') {
    for (const [key, value] of Object.entries(dataToSearchIn)) {
      if (errorKeysByPreference.includes(key)) {
        if (typeof value === 'string') {
          return value;
        }
      }
      const maybeErrorMessage = errorSearchDfs(value, errorKeysByPreference);
      if (valueExists(maybeErrorMessage)) {
        return maybeErrorMessage;
      }
    }
  }
};

const getErrorFromResponseData = (data, errorKeysByPreference = []) => {
  if (typeof data === 'string') {
    return data;
  }
  return errorSearchDfs(data, errorKeysByPreference);
};

export const axiosErrorHandler = (error, defaultMessage) => {
  let errorMessage = defaultMessage ?? 'An error occured. Please try again later';
  const statusCode = error?.response?.status;
  if (statusCode === 500) {
    errorMessage = 'An internal error occurred. Please try again later';
  } else if (statusCode === 0 && error?.request) {  // In case of 504, the error has 0 status code
    errorMessage = 'The server is too busy. Please try again later';
  } else {
    const maybeMessageFromError = getErrorFromResponseData(error?.response?.data ?? error, ['detail', 'message', 'reason', 'error']);
    if (valueExists(maybeMessageFromError)) {
      return maybeMessageFromError;
    }
  }

  if (statusCode === 401) {
    errorMessage = 'Incorrect username or password. Please log in again';
  } else if (statusCode === 404) {
    errorMessage = '404 Not Found';
  } else if (statusCode === 409) {
    errorMessage = 'This email address already exists';
  } else if (statusCode === 422) {
    const responseMsg = error?.response?.data?.detail?.[0]?.ctx?.reason;
    errorMessage = responseMsg ?? 'Data validation error. Please make sure all fields contain valid values';
  }

  let id = Sentry.captureException(Error(errorMessage));
  console.log('SentryID: ' + id + ', error: ' + errorMessage);

  return errorMessage;
};

export const getStaticPath = (url, path) => `${endpoints[url]}${path ? `/${path}` : ''}`;
