import { useContext, useState, useEffect } from 'react';
import { ApiContext } from 'context/api/ApiContext';
import apiTokenService from 'services/api-tokens-services';
import ApiKeysListHeading from './ApiKeysListHeading';
import Loader from 'components/loaders/loader/Loader';
import ApiKeysTableRow from './ApiKeysTableRow';
import { NoData } from 'components/no-data-container/NoData';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import useNotification from 'components/notification/UseNotification';

function ApiKeysListTable({ loading, setLoading, list, setList, generateNewApiKey }) {
  const activeTokens = list?.filter((token) => !token.disabled);
  const [copiedApiToken, setCopiedApiToken] = useState(null);
  const { apiToken, setApiToken } = useContext(ApiContext);
  const { deleteToken } = apiTokenService;
  const showNotification = useNotification();

  const handleDeleteKey = async (project_id, token_id) => {
    setLoading(true);
    const deleteResult = await deleteToken(project_id, token_id);
    if (deleteResult !== null) {
      showNotification('deleteApiKey', 'success');
      const filteredApiTokenList = list?.filter(
        (el) => el.id !== token_id,
      );
      setList(filteredApiTokenList);
      setApiToken(null);
    }
    setLoading(false);
  };

  const handleCopyKey = (token_id) => {
    if (copiedApiToken) return;

    setCopiedApiToken(token_id);
    showNotification('copyApiKey', 'success');

    setTimeout(() => {
      setCopiedApiToken(null);
    }, 3000);
  };

  const isNewTokenExists = (currentIndex, numberOfKeys) => {
    return currentIndex + 1 === numberOfKeys && apiToken;
  };

  const hashToken = () => {
    return Array.from({ length: 45 }).map(()=>'•').join('');
  };

  const displayApiKey = (currentIndex, numberOfKeys) => {

    return isNewTokenExists(currentIndex, numberOfKeys)
      ? apiToken.token
      : <div className='dots'>{hashToken()}</div>;
  };

  const displayCopyBtn = (currentIndex, numberOfKeys) => {
    return !!isNewTokenExists(currentIndex, numberOfKeys);
  };

  useEffect(() => {
    return () => setApiToken(null);
  }, []);

  if (loading || !activeTokens) return <Loader/>;

  if (activeTokens.length === 0) return (
    <NoData>
      <MainButton
        variant='button-no-data'
        label='CREATE NEW API KEY'
        icon={<UsersIcon/>}
        onClick={generateNewApiKey}
      />
    </NoData>
  );

  return (
    <>
      <ApiKeysListHeading/>
      <div className='api-keys-table-wrapper'>
        <div className='api-keys-body'>
          {activeTokens.map((token, index) => {
            return (
              <ApiKeysTableRow
                key={token?.id}
                token={token}
                displayApiKey={displayApiKey}
                index={index}
                activeTokens={activeTokens}
                displayCopyBtn={displayCopyBtn}
                copiedApiToken={copiedApiToken}
                apiToken={apiToken}
                handleCopyKey={handleCopyKey}
                handleDeleteKey={handleDeleteKey}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ApiKeysListTable;
