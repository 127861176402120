import styles from './style.module.scss';
import UsersManagementTableHeader from './UsersManagementTableHeader';
import UsersManagementTableBody from './UsersManagementTableBody';

const UsersManagementTable = ({ usersData, handleInviteClick, handleUserEdit, handleUserDelete, setEditedUserId }) => {
  return <div className={styles.managementTable}>
    <UsersManagementTableHeader handleInviteClick={handleInviteClick} />
    <UsersManagementTableBody
      usersData={usersData}
      handleUserEdit={handleUserEdit}
      handleUserDelete={handleUserDelete}
      setEditedUserId={setEditedUserId}
    />
  </div>;
};

export default UsersManagementTable;
