import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
  chartDefaultMargin,
  lineActiveDot,
} from 'components/charts/ChartHelper.js';
import { WIDGETS_ENUM } from 'utils/widgets-helper.js';
import { NoData } from 'components/no-data-container/NoData';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { useNavigate, useParams } from 'react-router-dom';

const CustomLineChart = ({
  risk,
  tooltip,
  widget,
  rangeKey,
  countKey
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const CustomTooltip = ({
    active,
    payload,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='title'>{payload[0].payload.count}</p>
          <p className='subtitle'>{payload[0].payload.date}</p>
        </div>
      );
    }
    return null;
  };

  if (widget.data.length === 0) {
    return (
      <NoData>
        <MainButton
          variant='button-no-data'
          label='IMPORT CLIENTS'
          icon={<UsersIcon/>} onClick={() => navigate(`/my-projects/${id}/clients`)}
        />
      </NoData>
    );
  }

  return ((
    <div className='custom-line-chart'>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          data={widget.data}
          margin={chartDefaultMargin}
        >
          <defs>
            <linearGradient id='greenPurpleGradient' gradientTransform='rotate(90)'>
              <stop offset='5%' stopColor='#7346F3'/>
              <stop offset='95%' stopColor='#6DFF96'/>
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id='greenRedGradient' gradientTransform='rotate(90)'>
              <stop offset='5%' stopColor='#F38446'/>
              <stop offset='95%' stopColor='#6DFF96'/>
            </linearGradient>
          </defs>
          <XAxis
            xAxisId='0'
            dataKey={rangeKey}
            axisLine={false}
            tickLine={false}
            padding={{ left: 20 }}
            minTickGap={20}
            interval={'preserveEnd'}
          />
          <YAxis
            type='number'
            domain={widget.name === WIDGETS_ENUM.RISK_SCORE ? [0, 10] : undefined}
            tickCount={3}
            axisLine={false}
            tickLine={false}
            padding={{
              bottom: 20,
              top: 10
            }}
          />
          {tooltip && <Tooltip content={<CustomTooltip/>}/>}
          <Line
            type='monotone'
            dataKey={countKey}
            stroke={risk ? 'url(\'#greenRedGradient\')' : 'url(\'#greenPurpleGradient\')'}
            strokeWidth={5}
            dot={() => null}
            activeDot={lineActiveDot}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
  );
};

export default CustomLineChart;
